import React, { Component } from 'react';
import { Row, Col, Typography, Button, Layout, Space, message, List, Card, Collapse, Select, Tooltip, Radio, Badge,ColorPicker, Tag } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { BsBox2 } from "react-icons/bs";
import { FaCashRegister } from 'react-icons/fa';
import { useNavigate, useLocation } from "react-router-dom";
import { UnorderedListOutlined, MenuOutlined, CloseCircleOutlined, CheckCircleOutlined, SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';

//Componentes
import User from '../../../Hooks/Logged';
import useSearch from '../../../Hooks/useSearch';
import SquareLogo from '../../Widgets/Icons/Square';
import { IconTruck } from '../../Widgets/Iconos';
import usePermissions from '../../../Hooks/usePermissions';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import FloatingButton from '../../Widgets/Floating Button/FloatingButton';
import { ButtonDelete, ButtonEdit, ButtonView } from '../../Widgets/Buttons';
import { renderSource, tiposServiciosVenta, getResponseError, paymentStatus, RenderSlot, tagDiasCreacion } from '../../Utils';

//Modales
import ModalVentas from './ModalVentas';
import ModalTerminal from './ModalTerminal';
import ModalVentasDetalles from "./ModalVentasDetalles";
import DrawerProductosVenta from './DrawerProductosVenta';

import '../../../Styles/Modules/Admin/clientes.scss'


const { Content } = Layout;
const { Text } = Typography
const { Option } = Select
/**
 *
 *
 * @export
 * @class VentasTab
 * @extends {Component}
 * @description Vista donde se listan las VEntas por estatus
 */
class VentasTab extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            ventas: {},
            panels: [0, 6, 1, 2, 3, 4, 7], //paneles activos
            search: this.props.search,
            first: true
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        this.props.setShowSearch(true)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getVentas({ search: this.props.search });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.getVentas({ search: this.props.search })
        }
    }

    /**
    * @memberOf VentasTab
    * @method loadMoreItems
    * @description Obtiene mas ventas solo de un estatus del que se busca
    */
    loadMoreItems(values){

        let { count, total } = this.state.ventas[values._id] ?? { count: 0, total: 1 }

        if(!this.state.loading && count < total ){

            this.setState({loading: true})
            axios.get('/ventas', {
                params: {
                    limit: 15,
                    search: this.props.search,
                    group: "estatus",
                    panels: [values._id],
                    skip: count,
                }
            })
                .then(({ data }) => {

                    if(Object.keys(data).length === 0){
                        message.info("No sales available")
                        return
                    }

                    this.setState({
                        ventas: {
                            ...this.state.ventas,
                            [values._id]:{
                                total: data[values._id].total,
                                count: data[values._id].count + count,
                                data: [ ...(this.state.ventas[values._id]?.data ?? []), ...data[values._id].data]
                            }
                        },
                    })
                })
                .catch(error => {
                    console.log(error)
                    message.error('Error getting information')
                })
                .finally(() => this.setState({ loading: false }))

        }
    }

    
    /**
    * @memberOf VentasTab
    * @method getVentas
    * @description Obtiene ls lista de Ventas 
    */
    getVentas = ({
        limit = 15,
        search = this.state.ventas.search,
        filters = this.state.ventas.filters,
        panels = this.state.panels,
    } = this.state.ventas) => {

        const params = { limit }
        if (search) params.search = search
        this.setState({ loading: true, panels,  })
        axios.get('/ventas', {
            params: {
                limit: limit,
                skip: 0,
                search: this.props.search,
                group: "estatus",
                panels,
                filters,
                
            }
        })
            .then(({ data }) => {
                this.setState({
                    ventas: {...data, search},
                    first: false
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error getting information')
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
   * @memberOf VentasTab
   * @method onChange
   * @description Cambia de vista de clientes de agrupados a listados
   */
    onChange = ({ target: { value } }) => {
        if (value === "list") this.props.navigate('/admin/sales/list')
    };
    
    /**
     * @memberOf VerntasTab
     * @method onChangePanels
     * @description Obtiene las ventas del estatus que se abrio
     */
    onChangePanels = (panels) => {
        console.log("panels", panels);

        panels = panels.map(e => parseInt(e))

        let paneles_activos = [...this.state.panels];

        // Encontrar el elemento añadido
        const addedPanel = panels.find(panel => !paneles_activos.includes(panel));

        if (addedPanel !== null && addedPanel !== undefined) {
            this.loadMoreItems({ _id: addedPanel })
        }

        this.setState({panels})
         
    }

    render() {
        const {user} = this.props;
        const { ventas, loading } = this.state

        let estatus = [
            {
                _id: 0,
                name: 'pendiente',
                label: "Pending"
            },
            {
                _id: 6,
                name: 'pendiente',
                label: "Pending-Paid"
            },
            {
                _id: 1,
                name: 'recibido',
                label: "Order Received"
            },
            {
                _id: 2,
                name: 'lavado',
                label: "Cleanning in Progress"
            },
            {
                _id: 3,
                name: 'detallado',
                label: "Final Detailing Touches"
            },
            {
                _id: 4,
                name: 'empaquetado',
                label: "Order Ready"
            },
            {
                _id: 7, //Este estatus no existe solo son el 4 con el flag de pick up en true
                name: 'empaquetado',
                label: "Order Ready (Pick Up)"
            },
            {
                _id: 5,
                name: 'entregado',
                label: "Order Completed"
            }
        ]


        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={"Sales"}
                    extra={<Space>
                        <Radio.Group
                            options={[
                                { label: <MenuOutlined />, value: "list" },
                                { label: <UnorderedListOutlined />, value: 'group' },
                            ]}
                            value={"group"}
                            onChange={this.onChange}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Space>}
                />
                <Content
                    className="admin-content content-bg clientes pd-1"
                    id="clientes-layout"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Collapse
                                bordered={false}
                                className="clientes-collapse w-100"
                                activeKey={this.state.panels}
                                onChange={panels => this.onChangePanels(panels)}
                            >
                                {estatus.map(({ _id, name, label }) => {
                                    return <Collapse.Panel
                                        key={_id}
                                        header={`${label} ${this.state.ventas[_id]?.count ?? 0} / ${this.state.ventas[_id]?.total ?? 0}`}
                                        className={`clientes-tab tab-${name}`}
                                    >
                                        <List
                                            loading={ventas.loading}
                                            className="component-list"
                                            itemLayout="horizontal"
                                            locale={{ emptyText: this.state.loading ? "Loading" : "Sales not found" }}
                                            dataSource={this.state.ventas[_id]?.data ?? []}
                                            renderItem={(venta, index) => (
                                                <List.Item className="component-list-item" key={venta._id}>
                                                    <Card className="card-list">
                                                        <Row align="middle">

                                                            <Col flex="auto">
                                                                <Row className="width-100 " gutter={[0, 6]}>
                                                                    <Col xs={3} className="flex-left-column" >
                                                                        <Text strong> {venta.cliente?.nombre ?? "-"} {venta.cliente?.apellidos}</Text>
                                                                        <small> {venta.folio ?? "-"} {venta.servicio_express ? <Tag color="red">EXPRESS</Tag> : null}</small>
                                                                    </Col>
                                                                    <Col xs={2} className="center" >
                                                                        <Text ellipsis >{
                                                                        (venta?.detalles?.length)?
                                                                            venta?.detalles?.reduce( function (acc,detalle){  return (detalle?.servicio_id[0]?.tipo == 0)? acc + detalle.cantidad : acc ; }, 0)
                                                                            :0
                                                                        } pairs </Text>
                                                                    </Col>
                                                                    <Col xs={3} className="center" >
                                                                        <Text ellipsis >{renderSource(venta.from_terminal)}</Text>
                                                                    </Col>
                                                                    <Col xs={1} className="flex-column" >
                                                                        <ColorPicker value={venta.detalles[0]?.primary_color ?? '#FFF'} disabled={true} />
                                                                    </Col>
                                                                    <Col xs={1} className="flex-column" >
                                                                        {tiposServiciosVenta(venta.servicios)}
                                                                    </Col>
                                                                    <Col xs={2} className="center" >
                                                                        <Text ellipsis >{paymentStatus(venta.payment_status==2)}</Text>
                                                                    </Col>
                                                                    <Col xs={1} className="flex-column" >
                                                                        { venta.pick_and_deliver_flag ? <Tooltip title={"Marked as Pick-Up & Delivery"}>
                                                                                <IconTruck /> 
                                                                            </Tooltip>: null }
                                                                    </Col>
                                                                    <Col xs={3} className="center" >
                                                                        { (user?.rol_id?.tipo == 1)? <Text ellipsis >$ {venta.total?.toMoney(true) ?? 0}</Text>  : null }
                                                                    </Col>
                                                                    <Col xs={1} className="center" >
                                                                        {tagDiasCreacion(venta.createdAt, _id)}
                                                                    </Col>
                                                                    <Col xs={3} className="flex-column" >
                                                                        <Text ellipsis >{dayjs(venta.createdAt).format("MM/DD/YYYY HH:mm")}</Text>
                                                                        { venta.collection_date ? <> <small> Collection Date: {dayjs(venta?.collection_date).format('MM/DD/YYYY')} </small>
                                                                        <small> {RenderSlot(venta?.time_slot)} </small> </>: null}
                                                                    </Col>
                                                                    <Col xs={4} className="center" >
                                                                        <Space wrap>
                                                                            <Tooltip title={`This sale is ${venta.square_id ? "" : "not"} registered on square`}>
                                                                                <Badge count={venta.square_id ? <CheckCircleOutlined color='#31A078' /> : <CloseCircleOutlined color='red' />}>
                                                                                    <Button type='primary'
                                                                                        disabled={true}
                                                                                        icon={<SquareLogo size="20px" />}
                                                                                        size="small"
                                                                                    />
                                                                                </Badge>
                                                                            </Tooltip>

                                                                            <Tooltip title={`Checkout on terminal`}>

                                                                                <Button type='primary'
                                                                                    disabled={venta.square_payment == true || !this.props.canEdit }
                                                                                    icon={<FaCashRegister />}
                                                                                    size="small"
                                                                                    onClick={() => this.setState({ modal_terminal_visible: true, venta_id: venta._id })}
                                                                                />

                                                                            </Tooltip>

                                                                            <Tooltip placement="topRight" title={'Add or edit items'}>
                                                                                <Button
                                                                                    size="small"
                                                                                    //disabled={venta.square_payment == true}
                                                                                    disabled = { !this.props.canEdit}
                                                                                    type='primary'
                                                                                    icon={<BsBox2 />}
                                                                                    onClick={() => this.setState({ modal_visible_drawer: true, venta_id: venta._id })}
                                                                                />
                                                                            </Tooltip>

                                                                            <ButtonView
                                                                                size="small"
                                                                                //disabled={user?.rol_id?.tipo != 1} 
                                                                                onClick={() => this.setState({ modal_visible: true, venta_id: venta._id })}
                                                                            />
                                                                            <ButtonEdit
                                                                                size="small"
                                                                                disabled={!this.props.canEdit}
                                                                                onClick={() => this.setState({ modalVentas: true, venta_id: venta._id })} />
                                                                            <ButtonDelete
                                                                                size="small"
                                                                                disabled={!this.props.canDelete || venta.square_payment == true}
                                                                                onConfirm={() => {
                                                                                    console.log("is paid", venta)
                                                                                    this.setState({ loading: true })
                                                                                    axios.delete('/ventas', { params: { venta_id: venta._id } })
                                                                                        .then((response) => {
                                                                                            message.success("It was deleted correctly")
                                                                                            this.getVentas()
                                                                                        })
                                                                                        .catch((error) => {
                                                                                            message.error(getResponseError(error.response, "Delete error"));
                                                                                            this.getVentas()
                                                                                        })
                                                                                }} />

                                                                        </Space>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </List.Item>
                                            )}
                                        />
                                        < Button 
                                        className='btn_cargar'
                                        icon={<SearchOutlined/>}
                                        loading={this.state.loading}
                                        onClick={  ()=>this.loadMoreItems({_id, name}) }> Load More </Button>
                                    </Collapse.Panel>
                                })}
                            </Collapse>
                        </Col>
                    </Row>
                </Content>

                {this.props.canCreate && <FloatingButton title="New Sale" disabled={!this.props.canCreate} onClick={() => this.setState({ modalVentas: true, venta_id: undefined })} />}

                <ModalVentasDetalles
                    visible={this.state.modal_visible}
                    onClose={() => {
                        this.setState({ modal_visible: false, venta_id: undefined })
                    }}
                    venta_id={this.state.venta_id}
                />

                <ModalVentas
                    visible={this.state.modalVentas}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modalVentas: false, venta_id: undefined })
                        this.getVentas()
                    }}
                />

                <ModalTerminal
                    visible={this.state.modal_terminal_visible}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modal_terminal_visible: false, venta_id: undefined })
                        this.getVentas()
                    }}
                />

                <DrawerProductosVenta
                    visible={this.state.modal_visible_drawer}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modal_visible_drawer: false, venta_id: undefined })
                        this.getVentas()
                    }}
                />


            </>
        )
    }
}

export default function Vista(props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["ventas", "create"],
        canEdit: ["ventas", "edit"],
        canDelete: ["ventas", "delete"],
        canViewDetails: ["ventas", "cliente-detalles"],
        canAssignAsesor: ["ventas", "assign-asesor"]
    })

    const navigate = useNavigate();
    const location = useLocation();


    return <VentasTab {...props}
        user={user}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
        location={location}
    />
}